<template>
    <div class="landing-wrapper">
        <t-header />
        <div class="container blog_text blog-post-wrapper">
            <div class="col-md-10 col-lg-8 mx-auto">
                <div class="mb-4">
                    <h1>
                        International students in the US – Covid-19 and tax
                        return filing requirements
                    </h1>
                    <p class="blog_author_text my-4">
                        The Thriftax Team
                        <br />
                        Jan 20 2021 · 5 min read
                    </p>
                </div>

                <div class="position-relative mb-5">
                    <img
                        class="card-img"
                        :src="getImgUrl('remote_study')"
                        alt="Image Description"
                    />
                </div>
                <p>
                    In 2020, many US colleges and universities transitioned to
                    online studying and ceased in-person classes, a move that
                    caused many foreign students in the US. to take classes from
                    their home countries or other locations outside the US Were
                    you a student in the US in 2020 and whose program switched
                    to online studies? Did you work from home over the summer or
                    as part of your academic internship? If you’re wondering
                    whether Covid-19 affected your US tax filing requirements,
                    keep reading.
                </p>
                <p>
                    As a rule of thumb, as an international student in the
                    US,<span class="font-weight-bold">
                        you are required to file US tax returns if</span
                    >:
                </p>
                <ul class="px-4">
                    <li>
                        You were employed by and received a salary from your
                        college or university
                    </li>
                    <li>
                        You were working for and received salary from a US
                        employer
                    </li>
                    <li>
                        You received a scholarship or fellowship that was paid
                        directly to you from a US source
                    </li>
                    <li>You won a prize or an award</li>
                    <li>
                        You received other US sourced income such as interest
                        income, dividends, capital gains etc.
                    </li>
                </ul>

                <h3 class="mt-4">
                    What if you’ve been continuing your studies remotely in your
                    home country due to Covid-19?
                </h3>

                <p>
                    Well, if one or more of the above events trigger a
                    requirement to file a US tax return, you still need to file,
                    no matter where you physically were! However, if, for
                    example, you took a paying summer internship with an
                    employer not located in the US, you do not need to file a US
                    tax return. What matters is the source of your income – if
                    it’s a US source you have to file a US tax return.
                </p>

                <p>
                    Need to file US taxes? Not sure? Thriftax can help! Go ahead
                    and
                    <a
                        href="javascript:;"
                        @click="$router.push({ name: 'Signup' })"
                        >start filing with Thriftax</a
                    >. Our software will determine if and what forms you are
                    required to file and walk you through the entire process.
                </p>
                <p>
                    Don’t need to file US tax returns? Awesome! As an
                    international student,
                    <span class="font-weight-bold"
                        >you (and your dependents if you have any) are still
                        required to file form 8843 and ship it to the IRS.</span
                    >
                </p>
                <p>
                    Form 8843 will ask you to fill in information about
                    yourself, your program, your current and previous visa
                    status, and the number of days you were present in the US.
                    It's simple as that! Nothing you can’t really file yourself,
                    but Thriftax is now supporting form 8843 and for $12.90 we
                    can file the form for you, with an optional shipping service
                    for $16.90.
                </p>
                <p>
                    The Thriftax family is wishing that you and your loved ones
                    stay safe and healthy!
                </p>

                <ReadyToFileBanner class="mt-8" />
                <blog-badges />
            </div>
        </div>
        <t-footer />
    </div>
</template>

<script>
import ReadyToFileBanner from '@/components/landings/ready-to-file-banner';
import BlogBadges from './blog-badges';

export default {
    components: {
        ReadyToFileBanner: ReadyToFileBanner,
        BlogBadges,
    },
    methods: {
        getImgUrl(image) {
            var images = require.context('@/assets/img/', false, /\.jpg$/);
            return images('./' + image + '.jpg');
        },
    },
};
</script>
